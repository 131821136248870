<template>
  <div class="my-attention">
    <div v-if="attend.length > 0" class="attention">全部关注({{ num }})</div>
    <!-- 取消成功弹窗 -->
    <div v-if="cancelFlag" class="cancelAtten">
      <img src="../../../../assets/images/cancelAtten.png" />
      <div>已成功取消关注</div>
    </div>
    <div v-if="attend.length > 0" style="height: 400px">
      <div v-for="(v, i) in attend" :key="i">
        <div class="item">
          <div class="content">
            <div class="item-img">
              <img class="head" src="@/assets/images/atten.png" />
              <img class="vip" src="@/assets/images/vip.png" />
            </div>
            <div class="item-text">
              <div>
                <span class="rico" v-text="v.focusName"></span>
                <span v-if="v.userType == 1" class="name">个人博主</span>
                <span v-if="v.userType == 2" class="name">机构博主</span>
                <span v-if="v.userType == 3" class="name">执业团队</span>
              </div>
              <div class="info">
                <span class="brief">简介：{{ v.introduction }}</span>
                <span class="more">更多></span>
              </div>
              <div class="look">查看作品</div>
            </div>
          </div>
          <div class="item-attent">
            <span v-if="v.focusType == 1" class="ok">✓</span>
            <span v-if="v.focusType == 1" class="over">已关注</span>
            <span v-if="v.focusType == 2" class="ok">⇋</span>
            <span v-if="v.focusType == 2" class="over">互相关注</span>
          </div>
          <div @click="cance(v.focusId)" class="cancel">
            <div>取消关注</div>
          </div>
        </div>
        <div class="xian"></div>
      </div>
    </div>
    <div v-if="attend.length < 0">
      <div class="noinfo">
        <img src="../../../../assets/images/noinfo.png" />
        <div>暂无关注</div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination
      v-if="attend.length > 1"
      class="pagination"
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      :pager-count="5"
      :page-size="pageSize"
      :page-count="pageCount"
      :current-page="pageNum"
      @current-change="currentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { focus, findFocusList } from "api/service";
export default {
  name: "MyAttention",
  data() {
    return {
      //当前页
      pageNum: 1,
      //一页大小
      pageSize: 11,
      //总页数
      pageCount: 1,
      //取消成功弹窗
      cancelFlag: false,
      //关注列表
      attend: [],
      //关注数量
      num: ""
    };
  },
  methods: {
    //获取关注列表
    async get_attend() {
      let data = {
        page: this.pageNum,
        limit: 10,
        type: 1
      };
      let res = await findFocusList(data);
      if (res.code == 200) {
        this.attend = res.data.data;
        this.num = res.data.total;
      }
    },
    //点击取消关注
    async cancel_attend(id) {
      let data = {
        type: 2,
        focusId: id
      };
      let res = await focus(data);
      if (res.code == 200) {
        this.get_attend();
        this.cancelFlag = true;
        setTimeout(this.cancel, 3000);
      }
    },
    //关闭取消成功弹窗
    cancel() {
      this.cancelFlag = false;
    },
    //选择页数
    currentChange(cur) {
      this.pageNum = cur;
      this.get_attend();
    },
    //取消关注弹窗
    cance(id) {
      this.$confirm("确定要取消关注吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.cancel_attend(id);
        })
        .catch(() => {});
    }
  },
  created() {
    this.get_attend();
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination {
  button {
    width: 80px;
  }
}
.my-attention {
  box-sizing: border-box;
  padding-top: 21px;
  padding-left: 20px;
  .pagination {
    margin: 20px 320px;
  }
  .cancelAtten {
    position: fixed;
    top: 150px;
    left: 850px;
    width: 186px;
    height: 53px;
    background: #ffffff;
    box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
    border-radius: 27px;
    box-sizing: border-box;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    div {
      width: 114px;
      height: 15px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
      margin-left: 11px;
      margin-top: -7px;
    }
  }
  .attention {
    width: 78px;
    height: 13px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    margin-bottom: 25px;
  }
  .noinfo {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 150px;
    div {
      width: 1100px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 28px;
      text-align: center;
      margin-top: 20px;
    }
  }
  .all {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    input {
      cursor: pointer;
    }
    span {
      width: 24px;
      height: 12px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
      margin-left: 24px;
      margin-top: -3px;
    }
  }
  .item {
    display: flex;
    align-items: center;
    position: relative;
    input {
      margin-right: 24px;
      cursor: pointer;
    }
    .content {
      display: flex;
      align-items: center;
      .item-img {
        position: relative;
        width: 70px;
        height: 70px;
        background: #fef;
        border: 1px solid #e9ebf2;
        border-radius: 50%;
        margin-right: 25px;
        .head {
          width: 70px;
          height: 70px;
          border-radius: 35px;
        }
        .vip {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
      }
      .item-text {
        .rico {
          width: 39px;
          height: 13px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-right: 15px;
        }
        .name {
          width: 48px;
          height: 12px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .info {
          margin-top: 11px;
          margin-bottom: 11px;
          .brief {
            width: 327px;
            height: 14px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 15px;
          }
          .more {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #4587ff;
            cursor: pointer;
          }
        }
        .look {
          height: 14px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4587ff;
          cursor: pointer;
        }
      }
    }
    .cancel {
      width: 66px;
      height: 29px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 14px;
      margin-left: 75px;
      box-sizing: border-box;
      padding: 5px 8px;
      cursor: pointer;
      position: absolute;
      right: 70px;
      div {
        width: 48px;
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
        line-height: 21px;
      }
    }
    .item-attent {
      width: 75px;
      height: 29px;
      background: #ffffff;
      border: 1px solid #999;
      border-radius: 14px;
      position: absolute;
      right: 170px;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      cursor: pointer;
      .over {
        width: 35px;
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999;
        margin-left: 3px;
      }
      .ok {
        width: 11px;
        height: 8px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999;
      }
    }
  }
  .xian {
    width: 970px;
    height: 1px;
    background: #f5f5f5;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
